import React from 'react';
import './App.css';

const App: React.FC = () => {
    const baseURI = 'https://kramerica-industries.s3-us-west-2.amazonaws.com/';
    const [generatedNumber, setGeneratedNumber] = React.useState<string>('');
    const vidRef = React.useRef(null);

    React.useEffect(() => {
        let preExistingEpisode = window.location.hash.replace('#', '');
        if (preExistingEpisode !== '' && preExistingEpisode !== '0') {
            setGeneratedNumber(preExistingEpisode);
        } else {
            let videoNumber = Math.floor(Math.random() * 177 + 1);
            console.log(videoNumber);
            setGeneratedNumber(videoNumber.toString());
        }
    }, []);

    if (generatedNumber === '') {
        return <div>Loading...</div>;
    } else {
        return (
            <main>
                <h1>A Festivus!</h1>
                <video
                    preload="auto"
                    autoPlay
                    playsInline
                    controls
                    style={{ width: '100%' }}
                    ref={vidRef}
                >
                    <source src={`${baseURI}${generatedNumber}.mp4`} />
                </video>
                <p>
                    <a href={'#' + generatedNumber}>Bookmark Episode</a> |{' '}
                    <button
                        style={{
                            display: 'inline',
                            border: 'none',
                            backgroundColor: 'initial',
                            color: '#0076ff',
                            fontSize: '16px',
                        }}
                        onClick={() => {
                            window.location.href = 'https://festivus.mom/';
                        }}
                    >
                        Refresh
                    </button>
                </p>
            </main>
        );
    }
};

export default App;
